


























import { BaseComponent, Component } from '@/components/BaseComponent';
import Error from '@/components/Error.vue';

@Component({
  components: {
    Error,
  },
})
export default class ResetPassword extends BaseComponent {
  successfullyReset = false;

  get code() {
    return this.store.getResetPasswordCode();
  }

  get lang() {
    return this.i18n.getLang();
  }

  password = '';

  async resetPassword() {
    this.error = null;
    const result = await this.pds.useResetPasswordCode({
      code: this.code,
      password: this.password,
      lang: this.lang,
    });
    if (result.isFailure()) {
      this.error = result.error;
    } else {
      this.successfullyReset = true;
    }
  }
}
